import React from 'react'

const About = () => {
  return (
    <div name="about" className='w-full h-full bg-gradient-to-b from-gray-800 to-black text-white'>
        <div className='max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-screen'>
            <div className='pt-12 md:pt-0'> {/* Add top padding only on medium and larger screens */}
                <p className='text-4xl font-bold inline border-b-4 border-gray-500'>
                About
                </p>
            </div>
            <p className='text-xl mt-10'>
            In my life there was handball🤾which allowed me to travel around Portugal, Erasmus took me around Europe and University gave me the knowledge I needed.
            </p>

            <br />

            <p className='text-xl'>
            But after some years of solving problems as a mechanical engineer there was something missing in my life...I wondered...I looked around...and there it was always in front of my eyes...computers 💻 CODE!!! 
            </p>

            <br />

            <p className='text-xl'>
            So now I lost myself in time solving problems again but with my eyes glowing and rubber ducking between never ending lines of code...I love it!!!
           </p>

           <br />

            <p className='text-xl'>
            So remember...every time you have a problem give me a call!!!
            </p>
        </div>
    </div>
  )
}

export default About
