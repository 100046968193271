import React from 'react'
import roadLife from "../assets/portfolio/roadlife.png";
import snake from '../assets/portfolio/snakeGame.png';
import html from '../assets/html.png';
import css from '../assets/css.png';
import javascprit from '../assets/javascript.png';
import csharp from '../assets/csharp.png';

const Portfolio = () => {

    const portfolios = [
        {
            id: 1,
            src: roadLife,
            tech1: css,
            tech2: html,
            tech3: javascprit,
            webSite: 'https://lifeontheroad.luis-santos.com'
        },

        {
            id: 1,
            src: snake,
            tech1: csharp,
            webSite: 'https://luis-santos.com/files/snake2.0.rar'         
        }
    ]

    return (
        <div name="portfolio" className='bg-gradient-to-b from-black to-gray-800 w-full h-full text-white'
        >
            <div className='max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full '>
                <div className='pt-8'>
                    <p className='text-4xl font-bold inline border-b-4 border-gray-500'>Portfolio</p>
                    <p className='py-6'>Check out some of my work right here</p>
                </div>
                <div className='grid sm:grid-cols-2 md:grid-cols-3 gap-8 px-12 sm:px-0 '>
                    {
                        portfolios.map(({ id, src, tech1, tech2, tech3, webSite }) => (
                            <div key={id} className='shadow-md shadow-gray-600 rounded-lg'>
                                 <div className=''>
                                 <img src={src} alt='' className='rounded-md duration-200 hover:scale-105 h-52' />
                                  </div>
                               
                                <div className='flex items-center justify-center'>
                                    <img src={tech2} alt="" className='w-20 p-4 ' />
                                    <img src={tech1} alt="" className='w-20 p-4 ' />
                                    <img src={tech3} alt="" className='w-20 p-4 ' />
                                </div>
                                <div className='flex items-center justify-center'  >
                                    <a href={webSite}
                                        className='w-20 px-5 py-2 m-3 duration-200 hover:scale-105 text-white flex items-center rounded-md bg-gradient-to-r from-cyan-500 to-blue-500 cursor-pointer'
                                        target="_blank" rel="noopener noreferrer"
                                        >
                                        Go to
                                    </a>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default Portfolio